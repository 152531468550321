var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticStyle:{"max-width":"100vw"}},[_c('v-toolbar',{staticClass:"px-4 py-3",attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" Soporte ")]),_c('v-spacer'),(!_vm.show_search_input)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.show_search_input=true}}},[_c('v-icon',[_vm._v("mdi-magnify")])],1):_vm._e(),(_vm.show_search_input)?_c('v-text-field',{staticClass:"mt-4",attrs:{"label":"Buscador"},model:{value:(_vm.search_input),callback:function ($$v) {_vm.search_input=$$v},expression:"search_input"}}):_vm._e(),(_vm.show_search_input)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.closeSearchInput()}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-0 px-12 pb-2",attrs:{"height":"500","fixed-header":"","footer-props":{'items-per-page-options':[15, 30, 50, _vm.totalTickets]},"headers":_vm.headers,"items":_vm.tickets,"options":_vm.options,"server-items-length":_vm.totalTickets,"loading":_vm.loading,"item-class":_vm.itemRowBackground + ' cursor-detail'},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:`item.priority`,fn:function({ item }){return [_c('v-chip',{style:('background:'
            + _vm.priorityColor(item.priority) +
            '; font-weight:600; border:solid 2px '
            + _vm.priorityColor(item.priority) +
            '; color:'
            + _vm.priorityColor(item.priority) +
            '; background-image: radial-gradient(rgb(255 255 255 / 90%),rgb(255 255 255 / 90%))'),attrs:{"small":""}},[_vm._v(_vm._s(item.priority))])]}},{key:`item.created_at`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.date(item))+" ")]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"900px"},model:{value:(_vm.detail_dialog),callback:function ($$v) {_vm.detail_dialog=$$v},expression:"detail_dialog"}},[_c('detail',{attrs:{"ticket":_vm.ticket},on:{"closeDetailDialog":_vm.closeDetailDialog}})],1),_c('v-dialog',{attrs:{"max-width":"1200px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"bottom":"","color":"#e25200","dark":"","fab":"","fixed":"","right":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-plus ")])],1)]}}]),model:{value:(_vm.create_dialog),callback:function ($$v) {_vm.create_dialog=$$v},expression:"create_dialog"}},[_c('create',{attrs:{"ticket":_vm.edited_ticket},on:{"closeCreateDialog":_vm.closeCreateDialog}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }